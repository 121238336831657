// extracted by mini-css-extract-plugin
export var authorComment = "style-mod--author-comment--OiqTd";
export var mainContainer = "style-mod--main-container--xTB4E";
export var tiContent = "style-mod--ti-content--rGgP2";
export var imageCol = "style-mod--image-col--1ln96";
export var contentCol = "style-mod--content-col--1cQeG";
export var contentTd = "style-mod--content-td--1-jNs";
export var title = "style-mod--title--1aUsJ";
export var description = "style-mod--description--2YsP3";
export var personTitle = "style-mod--person-title--znhvu";
export var personDesc = "style-mod--person-desc--1uKlC";
export var img = "style-mod--img--16fwi";