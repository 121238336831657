import React from "react";
import { Container } from "react-bootstrap";
import RichText from "components/rich-text";
import Image from "components/image";

import {
  authorComment, tiContent, description,
  img, contentTd,personTitle, personDesc,
  imageCol, contentCol, mainContainer,
} from "./style.mod.scss";

const AuthorComment = ({ data }) => {
  if (!data || !data.richtext) return <></>;

  return (
    <div className={authorComment}>
      <Container className={mainContainer}>
        <div className={tiContent}>
          <div className={imageCol}>
            <Image className={img} image={data.image} />
            <div>
              <h4 className={personTitle}>{ data.title }</h4>
              <span className={personDesc}>{ data.subtitle }</span>
            </div>
          </div>
          <div className={contentCol}>
            <div className={contentTd}>
              <RichText
                className={description}
                blocks={data.richtext}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AuthorComment;
